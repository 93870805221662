import React, { useState } from "react";

async function addEvmNetwork() {
  try {
    const result = await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [{
        chainId: "0x11d8",
        rpcUrls: ["https://testnet.dailychain.org/"],
        chainName: "Daily Chain Testnet",
        nativeCurrency: {
          name: "DAILY",
          symbol: "DLC",
          decimals: 18
        },
        blockExplorerUrls: ["https://dailyscan.io/"]
      }]
    });
  } catch (error){
    console.log(error)
  }
}

function App() {

  //js script part
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = () => {
      setIsOpen(!isOpen);
  };

  return (
    <div className="App">
      <>
        <header className="bg-transparent">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img src="./img/logo.png" alt="Daily Chain" />
              </a>
              <button
                  className="navbar-toggler"
                  onClick={openMenu}
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className={ isOpen ? 'collapse navbar-collapse show':'collapse navbar-collapse'} id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                        className="nav-link active"
                        aria-current="page"
                        href="#welcome"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#delegation">
                      Delegation
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#apps">
                      Apps
                    </a>
                  </li>
                </ul>
                <div className="d-flex">
                  <a href="https://docs.dailychain.org" className="btn">Docs</a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section id="welcome" className="overflow-hidden">
            <div className="container text-center h-100 inner-box">
              <div className="w-100">
                <h1 className="display-4 fw-bolder">
                  Daily Chain <sup className="colored">testnet</sup>
                </h1>
                <p className="lead">
                  🔗 Daily Chain is breaking new ground in the blockchain world with
                  the perfect
                  <br />
                  combination of EVM and DPoS! It offers unlimited potential with
                  smart contracts and fast,
                  <br />
                  energy-efficient transactions with DPoS.
                </p>
                <a href="#about">
                  Start Using Now
                </a>
              </div>
            </div>
            <div id="myearth" className="earth-container earth-ready">
              <div id="glow" />
              <canvas
                  width="100%"
                  height="100%"
                  style={{ display: "block", width: "100%", height: 943}}
              />
            </div>
          </section>
          <section id="about" className="text-center" >
            <div className="container">
              <h2 className="mb-3 fw-bold">
                Welcome to <span className="colored">Daily Chain</span>
              </h2>
              <p className="lead mb-0">
                Daily Chain is a high-performance blockchain <br /> that can process
                thousands of transactions per second.
              </p>
              <a href="" className="colored-btn mt-5">
                Explore Daily Chain
              </a>
            </div>
          </section>
          <section id="features">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-12">
                  <h3>
                    Made for <br /> everyone use<span>.</span>
                  </h3>
                </div>
                <div className="col-md-4 col-12">
                  <div className="card feature-box mt-100">
                    <div className="card-body">
                      <h5 className="card-title">Fast</h5>
                      <p className="card-text">
                        Daily Chain is a high-performance blockchain that can process
                        thousands of transactions per second.
                      </p>
                    </div>
                  </div>
                  <div className="card feature-box">
                    <div className="card-body">
                      <h5 className="card-title">Scalable</h5>
                      <p className="card-text">
                        Daily Chain is a scalable blockchain that can grow with your
                        business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="card feature-box">
                    <div className="card-body">
                      <h5 className="card-title">Secure</h5>
                      <p className="card-text">
                        Daily Chain is a secure blockchain that uses the Delegated
                        Proof of Stake (DPoS) consensus algorithm.
                      </p>
                    </div>
                  </div>
                  <div className="card feature-box">
                    <div className="card-body">
                      <h5 className="card-title">Decentralized</h5>
                      <p className="card-text">
                        Daily Chain is a decentralized blockchain that is owned and
                        operated by its community.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </div>
          </section>
          <section id="delegation" className="ctaBox py-5">
            <div className="container">
              <div className="delegation-box">
                <div className="inner-box">
                  <h2>Delegate your tokens and earn rewards</h2>
                  <p className="lead">
                    Delegate your tokens to a validator and earn rewards for securing
                    the network.
                  </p>
                  <a href="https://explorer.dailychain.org/" className="colored-btn">
                    Delegate Now
                  </a>
                </div>
                <div className="depth-clip" />
              </div>
            </div>
          </section>
          <section id="apps">
            <div className="container">
              <div className="title-box">
                <h3 className="text-center">
                  Build with <span className="colored">Daily.</span>
                </h3>
                <p className="text-center text-white">
                  Build dApps on Daily Chain <sup className="colored">Testnet</sup>
                </p>
              </div>
              <div className="row">
                <div className="col-md-5 col-12">
                  <div className="build-box">
                    <div
                        className="bg-image"
                        style={{ backgroundImage: 'url("./img/app-bg-1.png")' }}
                    />
                    <h6>DailySwap</h6>
                    <p className="text-white-50">
                      Decentralized swap exchange for DailyChain.
                    </p>
                    <a href="https://dailyswap.finance/" className="colored-btn mt-4">
                      Use dApp
                    </a>
                  </div>
                </div>
                <div className="col-md-7 col-12">
                  <div className="build-box">
                    <div
                        className="bg-image"
                        style={{ backgroundImage: 'url("./img/app-bg-4.png")' }}
                    />
                    <h6>Faucet</h6>
                    <p className="text-white-50">
                      Daily faucet: Complete tasks, earn micro rewards.
                    </p>
                    <a href="https://faucet.dailychain.org/" className="colored-btn mt-4">
                      Use dApp
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="launchNode" className="ctaBox py-5">
            <div className="container">
              <div className="delegation-box">
                <div className="inner-box">
                  <h2>
                    Launch your <span className="colored">Node!</span>
                  </h2>
                  <p className="lead">
                    By running your own node, contribute to the growth and
                    strengthening of our blockchain network.
                    <br /> This step not only enhances the security of the network but
                    also offers you the opportunity to verify
                    <br /> transactions and play a more active role in the network.
                    Together, let's expand the boundaries of blockchain technology.
                    Start now!
                  </p>
                  <a href="https://docs.daily.finance/daily-chain/developer-resources/local-daily-node" className="colored-btn">
                    Launch Now
                  </a>
                </div>
                <div className="depth-clip" />
              </div>
            </div>
          </section>
          <section id="connectChain">
            <div className="container">
              <div className="title-box">
                <h3 className="text-center">
                  Connect to{" "}
                  <span className="colored">
              Daily Chain <sup className="colored">Testnet</sup>
            </span>
                </h3>
                <p className="text-center text-white">
                  You can add Daily Chain to your crypto wallet using the RPC
                  information below. <br/> If you are using Metamask, just press the "Add
                  Network" button.
                </p>
              </div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="connect-box h-100">
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <img src="./img/metamask.svg" alt=""/>
                        <p className="text-white">
                          Add <span className="colored">Daily Chain Testnet</span> network
                          to Metamask.
                        </p>
                        <a onClick={addEvmNetwork} className="colored-btn mt-4">
                          Add Network
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="connect-box h-100">
                    <h6 className="text-white mb-4 colored">EVM Based</h6>
                    <p className="text-white mb-1">
                      Network Name:
                      <span className="float-end">Daily Chain Testnet</span>
                    </p>
                    <p className="text-white mb-1">
                      RPC URL: <span className="float-end">https://testnet.dailychain.org</span>
                    </p>
                    <p className="text-white mb-1">
                      Chain ID: <span className="float-end">4568</span>
                    </p>
                    <p className="text-white mb-1">
                      Symbol: <span className="float-end">DLC</span>
                    </p>
                    <p className="text-white mb-1">
                      Block Explorer:
                      <span className="float-end">https://dailyscan.io</span>
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="connect-box h-100">
                    <h6 className="text-white mb-4 colored">DPoS Based</h6>
                    <p className="text-white mb-1">
                      Network Name:
                      <span className="float-end">Daily Chain Testnet</span>
                    </p>
                    <p className="text-white mb-1">
                      RPC URL: <span className="float-end">https://daily-api.dailychain.org</span>
                    </p>
                    <p className="text-white mb-1">
                      Chain ID: <span className="float-end">4568</span>
                    </p>
                    <p className="text-white mb-1">
                      Symbol: <span className="float-end">DLC</span>
                    </p>
                    <p className="text-white mb-1">
                      Block Explorer:
                      <span className="float-end">https://dailyscan.io</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-12">
                <img
                    className="footer-logo mb-3"
                    src="./img/logo.png"
                    alt=""
                />
                <p className="text-white">
                  Daily Chain is a high-performance blockchain that can process
                  thousands of transactions per second.
                </p>
              </div>
              <div className="col-md-4 offset-md-4 col-12 text-md-end text-start mt-5 mt-md-0">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <h6 className="text-white">Quick Links</h6>
                    <ul className="list-unstyled">
                      <li>
                        <a href="#welcome">Home</a>
                      </li>
                      <li>
                        <a href="#about">About</a>
                      </li>
                      <li>
                        <a href="#delegation">Delegation</a>
                      </li>
                      <li>
                        <a href="#apps">Apps</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-12">
                    <h6 className="text-white">Social</h6>
                    <ul className="list-unstyled">
                      <li>
                        <a target="_blank" href="https://x.com/DailyChain_DLC">Twitter</a>
                      </li>
                      <li>
                        <a target="_blank" href="https://t.me/Daily_Chain">Telegram</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    </div>
  );
}

export default App;
